<template>
  <div>
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-text-field
        v-if="searchType === 'withCode'"
        v-model="search"
        append-icon="mdi-magnify"
        label="Enter your text and press enter to search"
        single-line
        hide-details
        @keydown.enter="searchWithCode"
      ></v-text-field>
      <div style="display: flex; margin-bottom: -1.5em" v-else>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="4">
          <v-menu
            v-model="startDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              no-title
              @input="handleStartDateChange"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-spacer></v-spacer> -->
        <v-col cols="12" sm="6" md="4">
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              no-title
              @input="handleEndDateChange"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="4" style="margin-right: -1.5em">
          <v-btn class="button" color="info" dark @click="searchByDate()">
            Search
          </v-btn>
        </v-col>
      </div>
    </v-card-title>
    <br />

    <v-data-table
      :headers="headers"
      :items="itemsNo"
      :loading="loading"
      loading-text="Please Wait....."
      disable-pagination
      :hide-default-footer="true"
      :item-key="itemKey"
      class="elevation-1 table-one"
      multi-sort
      @click:row="handleClick"
    >
      <template v-slot:item.name="{ item }">
        <div class="flex items-center">
          <p class="m-0 font-semibold">{{ item.name }}</p>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="flex">
          <div v-if="isStoreStock">
            <div v-if="updatePermission">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="update(item)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Update Quantity</span>
              </v-tooltip>
            </div>
          </div>
          <div v-if="!isStoreStock">
            <div v-if="viewPermission">
              <v-tooltip top>
                <template v-slot:activator="{ attrs }">
                  <v-btn
                    color="secondary"
                    dark
                    v-bind="attrs"
                    @click="$router.push(`${url}${item.id}`)"
                    icon
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
            </div>
          </div>
          <div v-if="isStore">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="info"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="assign(item)"
                >
                  <v-icon>mdi-plus-outline</v-icon>
                </v-btn>
              </template>
              <span>Assign User</span>
            </v-tooltip>
          </div>
          <div v-if="updatePermission">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="edit(item)"
                >
                  <v-icon>mdi-pencil-box-outline</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </div>
          <div v-if="deletePermission">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="danger"
                  dark
                  v-on="on"
                  v-bind="attrs"
                  @click="deleteData(item.id, item.name)"
                  icon
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:item.iron="{ item }">
        <v-sparkline
          :value="item.value"
          color="primary"
          height="100"
          padding="24"
          stroke-linecap="round"
          smooth
        >
        </v-sparkline>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currentPage"
      :length="totalPages"
      :total-visible="10"
      @input="handlePageChange"
      next-icon="mdi-menu-right"
      prev-icon="mdi-menu-left"
    ></v-pagination>
  </div>
</template>
<script>
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Datatables",
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: false,
    },
    Actions: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: true,
      required: true,
    },
    totalPages: {
      type: Number,
      default: 10,
      required: true,
    },
    searchType: {
      type: String,
      default: "withCode",
      required: false,
    },
    isStoreStock: {
      type: Boolean,
      default: false,
      required: false,
    },
    isStore: {
      type: Boolean,
      default: false,
      required: false,
    },
    itemKey: {
      type: String,
      default: "name",
      required: false,
    },
  },
  data() {
    return {
      singleSelect: false,
      selected: [],
      search: "",
      options: {},
      currentPage: 1,
      startDate: null,
      startDateMenu: false,
      endDateMenu: false,
      endDate: null,
      permission: this.$store.state.authData.userPermissions,
    };
  },
  computed: {
    itemsNo() {
      return this.items.map((d, index) => ({
        ...d,
        sno: d ? index + 1 : null,
        createdDate: d ? new Date(d.createdAt).toDateString() : null,
      }));
    },

    Sale() {
      if (this.title == "Sale") {
        return true;
      }
    },

    viewPermission() {
      if (this.title == "User") {
        if (this.permission.includes("view_users")) {
          return true;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("view_settings")) {
          return true;
        }
      }
      if (this.title == "Product") {
        if (this.permission.includes("view_product")) {
          return true;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("view_company_settings")) {
          return false;
        }
      }
      if (this.title == "Store") {
        if (this.permission.includes("view_store")) {
          return true;
        }
      }
      if (this.title == "Stock") {
        if (this.permission.includes("view_stock")) {
          return true;
        }
      }
      if (this.title == "Role") {
        if (this.permission.includes("view_roles")) {
          return true;
        }
      }
      if (this.title == "Sale") {
        if (this.permission.includes("view_sales")) {
          return true;
        }
      }
    },

    deletePermission() {
      if (this.title == "User") {
        if (this.permission.includes("delete_user")) {
          return true;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("delete_company_settings")) {
          return false;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("delete_settings")) {
          return true;
        }
      }
      if (this.title == "Product") {
        if (this.permission.includes("delete_product")) {
          return true;
        }
      }
      if (this.title == "Store") {
        if (this.permission.includes("delete_store")) {
          return true;
        }
      }
      if (this.title == "Stock") {
        if (this.permission.includes("delete_stock")) {
          return true;
        }
      }
      if (this.title == "Role") {
        if (this.permission.includes("delete_roles")) {
          return true;
        }
      }
      if (this.title == "Sale") {
        return false;
      }
      if (this.title == "Store Stock" || this.title == "Product Stock") {
        if (this.permission.includes("delete_stock")) {
          return true;
        }
      }
    },

    updatePermission() {
      if (this.title == "User") {
        if (this.permission.includes("update_user")) {
          return true;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("update_settings")) {
          return true;
        }
      }
      if (this.title == "Product") {
        if (this.permission.includes("update_product")) {
          return true;
        }
      }
      if (this.title == "Store") {
        if (this.permission.includes("update_store")) {
          return true;
        }
      }
      if (this.title == "Stock") {
        if (this.permission.includes("update_stock")) {
          return true;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("update_company_settings")) {
          return true;
        }
      }
      if (this.title == "Role") {
        if (this.permission.includes("update_roles")) {
          return true;
        }
      }
      if (this.title == "Store Stock" || this.title == "Product Stock") {
        if (this.permission.includes("update_stock_quantity")) {
          return true;
        }
      }
      if (this.title == "Sale") {
        return false;
      }
    },
  },
  methods: {
    handlePageChange(page) {
      this.currentPage = page;
      this.$emit("next", page);
    },

    handleClick(value) {
      this.$emit("click", value);
    },
    handleStartDateChange(value) {
      this.startDateMenu = false;
      this.startDate = value;
    },
    handleEndDateChange(value) {
      this.endDateMenu = false;
      this.endDate = value;
    },
    searchByDate() {
      this.$emit("searchByDate", this.startDate, this.endDate);
    },
    searchWithCode() {
      this.$emit("searchWithCode", this.search);
    },
    edit(item) {
      this.$emit("edit", item);
    },
    deleteData(item_id, item_name) {
      this.$emit("delete", item_id, item_name);
    },
    assign(item) {
      this.$emit("assign", item);
    },
    update(item) {
      this.$emit("update", item);
    },
  },
};
</script>
