const defaultDashboard = {
  Jan: 0,
  Feb: 0,
  Mar: 0,
  Apr: 0,
  May: 0,
  Jun: 0,
  Jul: 0,
  Aug: 0,
  Sep: 0,
  Oct: 0,
  Nov: 0,
  Dec: 0,
};

export default {
  state: {
    chartData: [],
    visitorsAnalytics: defaultDashboard,
  },
  getters: {
    chartData: (state) => state.chartData,
    visitorsAnalytics: (state) =>
      state.visitorsAnalytics ? state.visitorsAnalytics : defaultDashboard,
  },
  mutations: {
    setChartData: (state, payload) => {
      state.chartData = payload;
    },
    visitorsAnalytics: (state, payload) => {
      state.visitorsAnalytics = payload;
    },
  },
  actions: {
    setChartData: (commit, payload) => {
      commit("setChartData", JSON.parse(payload));
    },
    visitorsAnalytics: (commit, payload) => {
      commit("visitorsAnalytics", payload);
    },
  },
};
