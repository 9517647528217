import Swal from "sweetalert2";
import store from "@/store";

export function handleError(error) {
  if (error.graphQLErrors && error.graphQLErrors) {
    let errorMessage = error.graphQLErrors[0]["message"];
    if (errorMessage === "Token expired") {
      store.dispatch("signOut");
    } else if (errorMessage === "Token not valid. Re-login please") {
      store.dispatch("signOut");
    } else {
      Swal.fire({
        text: errorMessage,
        icon: "error",
      });
    }
  } else if (error.networkError && error.networkError) {
    let errorMessage = error.networkError[0]["message"];
    if (errorMessage === "Token expired") {
      store.dispatch("signOut");
    } else if (errorMessage === "Token not valid. Re-login please") {
      store.dispatch("signOut");
    } else {
      Swal.fire({
        text: errorMessage,
        icon: "error",
      });
    }
  } else {
    Swal.fire({
      text: error,
      icon: "error",
    });
  }
}
