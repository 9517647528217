var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.itemsNo,"item-key":"name","multi-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center"},[_c('p',{staticClass:"m-0 font-semibold"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex"},[(_vm.StoreStock)?_c('div',[(_vm.updatePermission)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.vDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Update Quantity")])])],1):_vm._e()]):_vm._e(),(!_vm.StoreStock)?_c('div',[(_vm.viewPermission)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"secondary","dark":"","icon":""},on:{"click":function($event){return _vm.$router.push(("" + _vm.url + (item.id)))}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])],1):_vm._e()]):_vm._e(),(_vm.Store)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","dark":"","icon":""},on:{"click":function($event){return _vm.AssignUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Assign User")])])],1):_vm._e(),(_vm.updatePermission)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1):_vm._e(),(_vm.deletePermission)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.deleteData(item.id, item.name)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1):_vm._e()])]}},{key:"item.iron",fn:function(ref){
var item = ref.item;
return [_c('v-sparkline',{attrs:{"value":item.value,"color":"primary","height":"100","padding":"24","stroke-linecap":"round","smooth":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }