/*
 * ============================
 * File: index.js
 * Project: egret-vue
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://themeforest.net/user/ui-lib
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2020 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import authenticate from "@/auth/authenticate";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      beforeEnter: authenticate,
      redirect: "/app/dashboard",

      children: [
        {
          path: "/app/dashboard",
          component: () => import("@/views/app/dashboard/Index"),

          children: [
            {
              path: "/",
              name: "sales",
              meta: {
                breadcrumb: [{ name: "Dashboard" }],
              },
              component: () => import("@/views/app/dashboard/Sales"),
            },
          ],
        },
        {
          path: "/app/audit-trail",
          component: () => import("@/views/app/auditTrail/Audit"),
          children: [
            {
              path: "/",
              name: "audit-trail",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Audit Trail" }],
              },
              component: () => import("@/views/app/auditTrail/AuditTrail"),
            },
          ],
        },
        {
          path: "/app/store",
          component: () => import("@/views/app/store/Store"),
          children: [
            {
              path: "/",
              name: "store",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Store" }],
              },
              component: () => import("@/views/app/store/getStoreList"),
            },
          ],
        },
        {
          path: "/app/store/view/:id",
          component: () => import("@/views/app/store/ViewStore"),
        },
        {
          path: "/app/sales",
          component: () => import("@/components/mayventory/Sales/addSales"),
          children: [
            {
              path: "/",
              name: "sales",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Sales" }],
              },
              component: () => import("@/components/mayventory/Sales/addSales"),
            },
          ],
        },
        {
          path: "/app/sales/view/:id",
          component: () => import("@/components/mayventory/Sales/viewSales"),
        },

        {
          path: "/app/sales/view",
          component: () => import("@/components/mayventory/Sales/Sales"),
        },
        {
          path: "/app/billings",
          component: () =>
            import("@/components/mayventory/Billing/billingHistory"),
          children: [
            {
              path: "/",
              name: "billings",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Sales" }],
              },
              component: () =>
                import("@/components/mayventory/Billing/billingHistory"),
            },
          ],
        },
        {
          path: "/app/billing/view/:id",
          component: () =>
            import("@/components/mayventory/Billing/viewBilling"),
        },

        {
          path: "/app/plan",
          component: () => import("@/components/mayventory/Billing/plans"),
        },
        {
          path: "/app/createPayment",
          name: "create_payment",
          component: () =>
            import("@/components/mayventory/Billing/CreatePayment"),
        },
        {
          path: "/app/company",
          component: () => import("@/views/app/company/BlankCompany"),
          children: [
            {
              path: "/",
              name: "company",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Company" }],
              },
              component: () => import("@/views/app/company/Company"),
            },
          ],
        },
        {
          path: "/app/company/view/",
          component: () => import("@/views/app/company/CompanyDetails"),
        },
        {
          path: "/app/users",
          component: () => import("@/components/mayventory/User/User"),
          children: [
            {
              path: "/",
              name: "user",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "User" }],
              },
              component: () => import("@/components/mayventory/User/User"),
            },
          ],
        },
        {
          path: "/app/user/view/:id",
          component: () => import("@/components/mayventory/User/ViewUser"),
        },
        {
          path: "/app/settings/edit/:id",
          component: () =>
            import("@/components/mayventory/Settings/editSettings"),
        },
        {
          path: "/app/settings/view/:id",
          component: () =>
            import("@/components/mayventory/Settings/viewSettings"),
        },
        {
          path: "/app/settings/addSettings",
          component: () =>
            import("@/components/mayventory/Settings/addSettings"),
        },
        {
          path: "/app/settings",
          component: () => import("@/components/mayventory/Settings/Settings"),
          children: [
            {
              path: "/",
              name: "settings",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Settings" }],
              },
              component: () =>
                import("@/components/mayventory/Settings/Settings"),
            },
          ],
        },
        {
          path: "/app/products",
          component: () => import("@/components/mayventory/Product/Product"),
          children: [
            {
              path: "/",
              name: "product",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Product" }],
              },
              component: () =>
                import("@/components/mayventory/Product/Product"),
            },
          ],
        },
        {
          path: "/app/product/view/:id",
          component: () =>
            import("@/components/mayventory/Product/ViewProduct"),
        },
        {
          path: "/app/productReturns",
          component: () =>
            import("@/components/mayventory/ProductReturns/ProductReturn"),
          children: [
            {
              path: "/",
              name: "productReturns",
              meta: {
                breadcrumb: [
                  { name: "Dashboard" },
                  { name: "Product Returns" },
                ],
              },
              component: () =>
                import("@/components/mayventory/ProductReturns/ProductReturn"),
            },
          ],
        },
        {
          path: "/app/createProductReturns",
          component: () =>
            import(
              "@/components/mayventory/ProductReturns/CreateProductReturn"
            ),
        },
        {
          path: "/app/productReturns/view/:id",
          component: () =>
            import("@/components/mayventory/ProductReturns/ViewProductReturn"),
        },
        {
          path: "/app/roles",
          component: () => import("@/components/mayventory/Role/Roles"),
          children: [
            {
              path: "/",
              name: "roles",
              meta: {
                breadcrumb: [{ name: "Dashboard" }, { name: "Role" }],
              },
              component: () => import("@/components/mayventory/Role/Roles"),
            },
          ],
        },
        {
          path: "/role/view/:id",
          component: () => import("@/components/mayventory/Role/ViewRole"),
        },
        // sessions

        // {
        //   path: "",
        //   name: "Home",
        //   component: () => import("@/views/home/Index.vue")
        // }
      ],
    },
    {
      path: "/error",
      component: () => import("@/views/app/errors/Errors"),
      children: [
        {
          path: "server-error",
          component: () => import("@/views/app/errors/500"),
        },
        {
          path: "not-found",
          component: () => import("@/views/app/errors/404"),
        },
      ],
    },
    {
      path: "/auth",
      component: () => import("@/views/app/auth/Auth"),
      beforeEnter: (to, from, next) => {
        if (
          localStorage.getItem("accessKey") != null &&
          localStorage.getItem("accessKey").length > 0
        ) {
          next("/app/dashboard");
        } else {
          localStorage.removeItem("accessKey");
          next();
        }
      },

      children: [
        {
          path: "/",
          component: () => import("@/views/app/auth/SignIn"),
        },
        {
          path: "forgot",
          component: () => import("@/views/app/auth/Forgot"),
        },
        {
          path: "lockscreen",
          component: () => import("@/views/app/auth/Lockscreen"),
        },
        // {
        //   path: "sign-up",
        //   component: () => import("@/views/app/auth/SignUp")
        // },
        // {
        //   path: "sign-up-2",
        //   name: "signup",
        //   component: () => import("@/views/app/auth/SignUpTwo")
        // },
        // {
        //   path: "sign-up-5",
        //   component: () => import("@/views/app/auth/SignUpFive")
        // }
      ],
    },
    // {
    //   path: "/audit-trail",
    //   component: () => import("@/views/app/auditTrail/AuditTrail"),
    //   beforeEnter: authenticate
    // }
  ],
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch("changeThemeLoadingState", true);
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
