<template>
  <div>
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="itemsNo"
      item-key="name"
      class="elevation-1 table-one"
      multi-sort
    >
      <template v-slot:item.name="{ item }">
        <div class="flex items-center">
          <p class="m-0 font-semibold">{{ item.name }}</p>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="flex">
          <div v-if="StoreStock">
            <div v-if="updatePermission">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="vDialog(item)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Update Quantity</span>
              </v-tooltip>
            </div>
          </div>
          <div v-if="!StoreStock">
            <div v-if="viewPermission">
              <v-tooltip top>
                <template v-slot:activator="{ attrs }">
                  <v-btn
                    color="secondary"
                    dark
                    v-bind="attrs"
                    @click="$router.push(`${url}${item.id}`)"
                    icon
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
            </div>
          </div>
          <div v-if="Store">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="info"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="AssignUser(item)"
                >
                  <v-icon>mdi-plus-outline</v-icon>
                </v-btn>
              </template>
              <span>Assign User</span>
            </v-tooltip>
          </div>
          <div v-if="updatePermission">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="edit(item)"
                >
                  <v-icon>mdi-pencil-box-outline</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </div>
          <div v-if="deletePermission">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="danger"
                  dark
                  v-on="on"
                  v-bind="attrs"
                  @click="deleteData(item.id, item.name)"
                  icon
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:item.iron="{ item }">
        <v-sparkline
          :value="item.value"
          color="primary"
          height="100"
          padding="24"
          stroke-linecap="round"
          smooth
        >
        </v-sparkline>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Datatables",
  },
  props: ["headers", "items", "Actions", "title", "url"],
  data() {
    return {
      singleSelect: false,
      selected: [],
      search: "",
      permission: this.$store.state.authData.userPermissions,
    };
  },
  computed: {
    itemsNo() {
      return this.items.map((d, index) => ({ ...d, sno: index + 1 }));
    },
    Store() {
      if (this.title == "Store") {
        return true;
      }
    },
    Sale() {
      if (this.title == "Sale") {
        return true;
      }
    },
    StoreStock() {
      if (this.title == "Store Stock" || this.title == "Product Stock") {
        return true;
      }
    },
    viewPermission() {
      if (this.title == "User") {
        if (this.permission.includes("view_users")) {
          return true;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("view_settings")) {
          return true;
        }
      }
      if (this.title == "Product") {
        if (this.permission.includes("view_product")) {
          return true;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("view_company_settings")) {
          return false;
        }
      }
      if (this.title == "Store") {
        if (this.permission.includes("view_store")) {
          return true;
        }
      }
      if (this.title == "Stock") {
        if (this.permission.includes("view_stock")) {
          return true;
        }
      }
      if (this.title == "Role") {
        if (this.permission.includes("view_roles")) {
          return true;
        }
      }
      if (this.title == "Sale") {
        if (this.permission.includes("view_sales")) {
          return true;
        }
      }
    },

    deletePermission() {
      if (this.title == "User") {
        if (this.permission.includes("delete_user")) {
          return true;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("delete_company_settings")) {
          return false;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("delete_settings")) {
          return true;
        }
      }
      if (this.title == "Product") {
        if (this.permission.includes("delete_product")) {
          return true;
        }
      }
      if (this.title == "Store") {
        if (this.permission.includes("delete_store")) {
          return true;
        }
      }
      if (this.title == "Stock") {
        if (this.permission.includes("delete_stock")) {
          return true;
        }
      }
      if (this.title == "Role") {
        if (this.permission.includes("delete_roles")) {
          return true;
        }
      }
      if (this.title == "Sale") {
        return false;
      }
      if (this.title == "Store Stock" || this.title == "Product Stock") {
        if (this.permission.includes("delete_stock")) {
          return true;
        }
      }
    },

    updatePermission() {
      if (this.title == "User") {
        if (this.permission.includes("update_user")) {
          return true;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("update_settings")) {
          return true;
        }
      }
      if (this.title == "Product") {
        if (this.permission.includes("update_product")) {
          return true;
        }
      }
      if (this.title == "Store") {
        if (this.permission.includes("update_store")) {
          return true;
        }
      }
      if (this.title == "Stock") {
        if (this.permission.includes("update_stock")) {
          return true;
        }
      }
      if (this.title == "Settings") {
        if (this.permission.includes("update_company_settings")) {
          return true;
        }
      }
      if (this.title == "Role") {
        if (this.permission.includes("update_roles")) {
          return true;
        }
      }
      if (this.title == "Store Stock" || this.title == "Product Stock") {
        if (this.permission.includes("update_stock_quantity")) {
          return true;
        }
      }
      if (this.title == "Sale") {
        return false;
      }
    },
  },
  methods: {
    edit(item) {
      if (this.title == "Product") {
        this.$root.$refs.A.edit(item);
      }
      if (this.title == "User") {
        this.$root.$refs.U.edit(item);
      }
      if (this.title == "Role") {
        this.$root.$refs.B.edit(item);
      }
      if (this.title == "Store") {
        this.$root.$refs.S.editItem(item);
      }
      if (this.title == "Store Stock") {
        this.$root.$refs.VS.edit(item);
      }
      if (this.title == "Product Stock") {
        this.$root.$refs.VP.edit(item);
      }
      if (this.title == "Settings") {
        this.$root.$refs.ST.edit(item);
      }
    },
    deleteData(itemId, itemName) {
      if (this.title == "Product") {
        this.$root.$refs.A.deleteProduct(itemId, itemName);
      }
      if (this.title == "Role") {
        this.$root.$refs.B.deleteRole(itemId, itemName);
      }
      if (this.title == "User") {
        this.$root.$refs.U.deleteUser(itemId, itemName);
      }
      if (this.title == "Store") {
        this.$root.$refs.S.deleteItem(itemId);
      }
      if (this.title == "Store Stock") {
        this.$root.$refs.VS.deleteStock(itemId);
      }
      if (this.title == "Product Stock") {
        this.$root.$refs.VP.deleteStock(itemId);
      }
    },

    AssignUser(item) {
      if (this.title == "Store") {
        this.$root.$refs.S.assignUser(item);
      }
    },

    vDialog(item) {
      if (this.title == "Store Stock") {
        this.$root.$refs.VS.vDialog(item);
      }
      if (this.title == "Product Stock") {
        this.$root.$refs.VP.vDialog(item);
      }
    },
  },
};
</script>
