import Vuex from "vuex";
import Vue from "vue";
import largeSidebar from "./modules/largeSidebar";
import compactSidebar from "./modules/compactSidebar";
import themeConfig from "./modules/themeConfig";
import authData from "./modules/authData";
import invoice from "./modules/invoice";
import dashboard from "./modules/dashboard";
import verticalSidebar from "./modules/verticalSidebar";
import permissions from "./modules/mayventory/permissions";
import createPersistedState from "vuex-persistedstate";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    themeConfig,
    authData,
    invoice,
    verticalSidebar,
    permissions,
    dashboard,
  },
  plugins: [createPersistedState()],
});
