import gql from "graphql-tag";

// Dashboard Query

export const GET_DASHBOARD_DATAS = gql`
  query getDashboard {
    getDashboard {
      productCount
      chartData
      atRiskItems {
        id
        quantity
        product {
          id
          name
          code
        }
        store {
          id
          name
          address
        }
      }
      staffs {
        id
        email
        name
        address
        role {
          name
        }
      }
      audit {
        id
        ipAddress
        operation
        browser
        createdAt
        company {
          name
        }
        user {
          email
          name
        }
      }
      sales {
        id
        invoiceId
        totalPrice
        paymentType
        paymentStatus
        stockHistories {
          id
          purchasedQuantity
          stock {
            id
            quantity
          }
        }
        createdAt
      }
      company {
        name
        domain
        address
      }
      salesCurrentMonth
    }
  }
`;

// Authentication Queries

export const LOGIN_QUERY = gql`
  mutation loginUser($email: String!, $password: String!, $domain: String!) {
    userLogin(email: $email, password: $password, domain: $domain) {
      token
      permissions
      firstLogin
      store {
        id
      }
      user {
        name
        role {
          name
        }
        company {
          id
          name
          domain
          address
        }
      }
      store {
        company {
          domain
        }
      }
    }
  }
`;

export const REGISTER_QUERY = gql`
  mutation RegisterUser(
    $name: String!
    $adminEmail: String!
    $adminName: String!
    $password: String!
    $domain: String!
    $address: String!
  ) {
    registration(
      company: {
        name: $name
        adminEmail: $adminEmail
        adminName: $adminName
        password: $password
        domain: $domain
        address: $address
      }
    ) {
      user {
        name
      }
    }
  }
`;

export const SEND_MAIL = gql`
  mutation sendinvoicemail($recipientEmail: String!, $salesId: Int!) {
    sendInvoiceMail(
      mail: { recipientEmail: $recipientEmail, salesId: $salesId }
    ) {
      message
    }
  }
`;

export const LOGOUT_QUERY = gql`
  mutation logOutUser {
    userLogout {
      message
    }
  }
`;

// Sales Query

export const GET_SALES_LIST = gql`
  query getSalesList(
    $currentPage: Int
    $perPage: Int
    $startDate: Date
    $endDate: Date
  ) {
    getSalesList(
      currentPage: $currentPage
      perPage: $perPage
      startDate: $startDate
      endDate: $endDate
    ) {
      sales {
        id
        stockHistories {
          initialQuantity
          finalQuantity
          purchasedQuantity
          stock {
            quantity
            product {
              name
              code
            }
            store {
              name
              address
            }
          }
        }
        totalPrice
        tax
        discount
        invoiceId
        paymentType
        paymentStatus
        createdAt
      }
      perPage
      currentPage
      total
      nextPage
      previousPage
      lastPage
    }
  }
`;

export const GET_STORE_SALES_LIST = gql`
  query getStoreSalesList($currentPage: Int, $perPage: Int, $storeId: Int) {
    getStoreSalesList(
      currentPage: $currentPage
      perPage: $perPage
      storeId: $storeId
    ) {
      sales {
        id
        stockHistories {
          initialQuantity
          finalQuantity
          purchasedQuantity
          stock {
            quantity
            product {
              name
              code
            }
            store {
              name
              address
            }
          }
        }
        totalPrice
        tax
        discount
        invoiceId
        paymentType
        paymentStatus
        createdAt
      }
      perPage
      currentPage
      total
      nextPage
      previousPage
      lastPage
    }
  }
`;

export const GET_SINGLE_SALES = gql`
  query getSingleSales($salesId: Int!) {
    getSingleSales(salesId: $salesId) {
      id
      stockHistories {
        initialQuantity
        finalQuantity
        purchasedQuantity
        stock {
          quantity
          product {
            name
            code
            price
          }
          store {
            name
            address
          }
        }
      }
      totalPrice
      calculatedPrice
      tax
      discount
      invoiceId
      paymentType
      paymentStatus
      createdAt
    }
  }
`;

export const GET_SALES_WITH_INVOICE = gql`
  query getSalesWithInvoiceCode($invoiceCode: String!) {
    getSalesWithInvoiceCode(invoiceCode: $invoiceCode) {
      id
      stockHistories {
        initialQuantity
        finalQuantity
        purchasedQuantity
        stock {
          quantity
          product {
            name
            code
          }
          store {
            name
            address
          }
        }
      }
      totalPrice
      tax
      discount
      invoiceId
      paymentType
      paymentStatus
      createdAt
    }
  }
`;

// Sales Mutation
export const CREATE_NEW_SALES = gql`
  mutation createNewSale(
    $stocks: String!
    $paymentType: String!
    $paymentStatus: String!
    $discount: Float
    $totalPrice: Float!
    $tax: Float
  ) {
    createNewSales(
      sales: {
        stocks: $stocks
        totalPrice: $totalPrice
        discount: $discount
        tax: $tax
        paymentType: $paymentType
        paymentStatus: $paymentStatus
      }
    ) {
      id
      invoiceId
      paymentType
      paymentStatus
      totalPrice
      tax
      discount
    }
  }
`;

// Permission Queries

export const PERMISSION = gql`
  query getPermissions {
    getPermissions {
      name
      code
    }
  }
`;

export const ADD_ROLE = gql`
  mutation roleSchema($name: String!, $permissionList: [String]) {
    createNewRole(role: { name: $name, permissionList: $permissionList }) {
      name
      permissions {
        id
        name
        code
      }
    }
  }
`;

export const GET_ROLES = gql`
  query getRoles {
    getRoles {
      id
      name
      permissions {
        id
        name
        code
      }
      permissionList
    }
  }
`;

export const GET_ROLE = gql`
  query getSingleRoleSchema($id: Int!) {
    getSingleRole(roleId: $id) {
      id
      name
      permissions {
        id
        name
        code
      }
      permissionList
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation roleUpdateSchema(
    $roleId: Int!
    $name: String
    $permissionList: [String]
  ) {
    updateRoleDetails(
      role: { roleId: $roleId, name: $name, permissionList: $permissionList }
    ) {
      id
      name
    }
  }
`;

// Billing Queries

export const GET_BILLING_HISTORY = gql`
  query getBillingHistory {
    getBillingHistory {
      id
      plan
      company {
        id
        name
        domain
        nextPayment
      }
      billingHistory {
        id
        transactionId
        paymentType
        transactionStatus
        numMonths
      }
    }
  }
`;

export const GET_ALL_PLAN = gql`
  query getAllPlans {
    getAllPlan {
      name
      numStaff
      numRoles
      numProducts
      numStores
      numSales
      hasSupport
      price
      featureRequest
    }
  }
`;

// User Queries

export const CREATE_NEW_USER = gql`
  mutation createUser(
    $roleId: Int!
    $email: String!
    $name: String!
    $address: String
    $dob: String
  ) {
    createNewUser(
      user: {
        roleId: $roleId
        email: $email
        name: $name
        address: $address
        dob: $dob
      }
    ) {
      id
      name
      password
      email
      company {
        id
        domain
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      message
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getCompanyUsers {
    getCompanyUsers {
      id
      name
      email
      address
      password
      sex
      dob
      role {
        id
        name
        permissionList
      }
    }
  }
`;

export const GET_USER_DETAIL = gql`
  query getSingleUserDetail($userId: Int!) {
    getSingleUser(userId: $userId) {
      name
      email
      address
      dob
      sex
      role {
        name
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($userId: Int!) {
    deleteUser(userId: $userId) {
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUserDetail(
    $userId: Int!
    $email: String
    $name: String
    $address: String
    $dob: String
    $roleId: Int
  ) {
    updateUserDetail(
      user: {
        userId: $userId
        email: $email
        name: $name
        address: $address
        dob: $dob
        roleId: $roleId
      }
    ) {
      id
      name
      email
      role {
        name
      }
    }
  }
`;

// Product Queries

export const ADD_PRODUCT = gql`
  mutation createProduct($name: String!, $price: Float!, $code: String!) {
    createProduct(product: { name: $name, price: $price, code: $code }) {
      id
      companyId
      name
      price
      code
    }
  }
`;

export const VIEW_ALL_PRODUCTS = gql`
  query getProductList($currentPage: Int!, $perPage: Int!) {
    getProductList(currentPage: $currentPage, perPage: $perPage) {
      products {
        id
        name
        price
        code
      }
      perPage
      currentPage
      total
      nextPage
      previousPage
      lastPage
    }
  }
`;

export const GET_ALL_PRODUCTS_FOR_DROPDOWN = gql`
  query getAllProducts {
    getAllProducts {
      id
      name
      price
      code
    }
  }
`;

export const VIEW_SINGLE_PRODUCT = gql`
  query getSingleProductDetails($productId: Int!) {
    getSingleProductDetails(productId: $productId) {
      id
      name
      code
      price
    }
  }
`;

export const GET_PRODUCT_BY_CODE = gql`
  query getProductByCode($productCode: String!) {
    getProductByCode(productCode: $productCode) {
      id
      name
      price
      code
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: Int!, $name: String!, $price: Float!) {
    updateProduct(product: { id: $id, name: $name, price: $price }) {
      id
      companyId
      name
      price
      code
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($productId: Int!) {
    deleteProduct(productId: $productId) {
      message
    }
  }
`;

// Product Return

export const GET_PRODUCT_RETURN_LIST = gql`
  query getProductReturnSchema {
    getProductReturnList {
      id
      refundPrice
      comment
      paymentType
      refundType
      paymentStatus
      ingestStock
      salesId
      company {
        id
      }
      stock {
        id
        quantity
        product {
          id
          name
        }
        store {
          id
          name
        }
      }
    }
  }
`;

export const GET_SINGLE_PRODUCT_RETURN = gql`
  query getSingleProductReturn($productReturnId: Int!) {
    getSingleProductReturn(productReturnId: $productReturnId) {
      id
      refundPrice
      comment
      paymentType
      refundType
      paymentStatus
      ingestStock
      salesId
      company {
        id
      }
      stock {
        id
        quantity
        product {
          id
          name
        }
        store {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_PRODUCT_RETURNS = gql`
  mutation createProductReturn(
    $stocks: [String]!
    $paymentType: String!
    $refundType: String!
    $paymentStatus: String!
    $salesId: Int!
  ) {
    createProductReturns(
      productReturns: {
        stocks: $stocks
        paymentType: $paymentType
        refundType: $refundType
        paymentStatus: $paymentStatus
        salesId: $salesId
      }
    ) {
      id
      refundPrice
      comment
      paymentType
      refundType
      paymentStatus
      ingestStock
      salesId
      company {
        id
      }
      stock {
        id
        quantity
        product {
          id
          name
        }
        store {
          id
          name
        }
      }
    }
  }
`;

// AuditTrail Queries

export const COMPANY_AUDIT_TRAIL = gql`
  query getAuditTrail(
    $currentPage: Int
    $perPage: Int
    $startDate: Date
    $endDate: Date
  ) {
    getAuditTrail(
      currentPage: $currentPage
      perPage: $perPage
      startDate: $startDate
      endDate: $endDate
    ) {
      auditTrails {
        ipAddress
        operation
        browser
        user {
          name
        }
        createdAt
      }
      perPage
      currentPage
      total
      nextPage
      previousPage
      lastPage
    }
  }
`;

// Stock Query

export const GET_PRODUCT_STOCK_LIST = gql`
  query getProductStockList($productId: Int!) {
    getProductStockList(productId: $productId) {
      id
      quantity
      product {
        id
        name
        price
        code
        company {
          id
          name
        }
      }
      store {
        id
        name
        address
        company {
          id
          name
        }
      }
    }
  }
`;

export const GET_STORE_STOCK_LIST = gql`
  query getStoreStockList($storeId: Int!) {
    getStoreStockList(storeId: $storeId) {
      id
      quantity
      product {
        id
        name
        price
        code
        company {
          id
          name
        }
      }
      store {
        id
        name
        address
        company {
          id
          name
        }
      }
    }
  }
`;

export const ADD_STOCK = gql`
  mutation createNewStock($productId: Int!, $storeId: Int!, $quantity: Int!) {
    createNewStock(
      stock: { productId: $productId, storeId: $storeId, quantity: $quantity }
    ) {
      id
      quantity
      product {
        id
        name
        code
      }
      store {
        id
        name
        address
      }
    }
  }
`;

export const UPDATE_STOCK = gql`
  mutation updateStock(
    $stockId: Int!
    $storeId: Int
    $productId: Int
    $quantity: Int
  ) {
    updateStock(
      stock: {
        stockId: $stockId
        productId: $productId
        storeId: $storeId
        quantity: $quantity
      }
    ) {
      id
      quantity
      product {
        id
        name
        code
        company {
          id
          name
        }
      }
      store {
        id
        name
        address
        company {
          id
          name
        }
      }
    }
  }
`;

export const VIEW_SINGLE_STORE = gql`
  query getSingleStore($storeId: Int!) {
    getSingleStore(storeId: $storeId) {
      id
      name
      address
      company {
        id
        name
        domain
        address
      }
    }
  }
`;

export const DELETE_STOCK = gql`
  mutation deleteStock($stockId: Int!) {
    deleteStock(stockId: $stockId) {
      message
    }
  }
`;

export const UPDATE_STOCK_QUANTITY = gql`
  mutation updateStockQuantity($stockId: Int!, $quantity: Int!) {
    updateStockQuantity(stock: { stockId: $stockId, quantity: $quantity }) {
      id
      quantity
      product {
        id
        name
        code
        company {
          id
          name
        }
      }
      store {
        id
        name
        address
        company {
          id
          name
        }
      }
    }
  }
`;

export const GET_USER_STORES = gql`
  query getUserStoreList {
    getUserStoreList {
      id
      name
      address
      company {
        id
        name
        address
      }
    }
  }
`;

// Company Store Queries

export const COMPANY_STORE = gql`
  query getCompanyStoreList {
    getCompanyStoreList {
      id
      name
      address
      company {
        id
        name
        domain
        address
      }
    }
  }
`;

// add company storev

export const ADD_COMPANY_STORE = gql`
  mutation StoreSchema($name: String!, $address: String!) {
    createNewStore(store: { name: $name, address: $address }) {
      id
      name
      address
    }
  }
`;

// update company storev

export const UPDATE_COMPANY_STORE = gql`
  mutation StoreSchema($storeId: Int!, $name: String, $address: String) {
    updateStore(store: { storeId: $storeId, name: $name, address: $address }) {
      id
    }
  }
`;

// Delete company storev

export const DELETE_COMPANY_STORE = gql`
  mutation deleteStore($storeId: Int!) {
    deleteStore(storeId: $storeId) {
      message
    }
  }
`;

// Assign User To Store

export const ASSIGN_USER_TO_STORE = gql`
  mutation assignUserStore($userId: Int!, $storeId: Int) {
    updateUserDetail(user: { userId: $userId, storeId: $storeId }) {
      name
      store {
        id
        name
        address
      }
    }
  }
`;

// Company Queries

export const GET_ALL_COMPANIES = gql`
  query getCompanyList {
    getCompanyList {
      id
      name
      domain
      address
      nextPayment
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation deleteCompany($companyId: Int!) {
    deleteCompany(companyId: $companyId) {
      message
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteRole($roleId: Int!) {
    deleteRole(roleId: $roleId) {
      message
    }
  }
`;

export const ADD_COMPANY = gql`
  mutation createCompany(
    $name: String!
    $adminEmail: String!
    $domain: String!
    $address: String
  ) {
    createNewCompany(
      company: {
        name: $name
        adminEmail: $adminEmail
        domain: $domain
        address: $address
      }
    ) {
      company {
        name
        address
        domain
        nextPayment
      }
      user {
        email
        password
        name

        role {
          name
        }
      }
    }
  }
`;

export const EDIT_COMPANY = gql`
  mutation updateCompany($companyId: Int!, $address: String, $name: String) {
    updateCompany(companyId: $companyId, address: $address, name: $name) {
      company {
        name
        domain
        address
        nextPayment
      }
    }
  }
`;

// Setting

export const GET_SETTINGS = gql`
  query getSettingsList {
    getSettingsList {
      id
      name
      code
      type
    }
  }
`;

export const GET_SINGLE_SETTINGS = gql`
  query getSingliSetting($settingId: Int!) {
    getSingleSettings(settingsId: $settingId) {
      id
      name
      code
      type
    }
  }
`;

export const GET_COMPANY_SETTINGS = gql`
  query getCompanySettingsList {
    getCompanySettingsList {
      id
      value
      name
      code
      type
      company {
        id
        name
      }
    }
  }
`;

export const GET_COMPANY_SINGLE_SETTINGS = gql`
  query getSingleCompanySettings($settingsId: Int!) {
    getSingleCompanySettings(settingsId: $settingsId) {
      id
      value
      id
      name
      code
      type
      company {
        id
        name
      }
    }
  }
`;

export const ADD_COMPANY_SETTINGS = gql`
  mutation addCompanySetting($settingsId: Int!, $value: String!) {
    addCompanySettings(
      companySettings: { settingsId: $settingsId, value: $value }
    ) {
      id
      value
      id
      name
      code
      type
      company {
        id
        name
      }
    }
  }
`;

export const UPDATE_COMPANY_SETTINGS = gql`
  mutation updateCompanySetting($settingsId: Int!, $value: String!) {
    updateCompanySettings(settingsId: $settingsId, value: $value) {
      id
      value
      id
      name
      code
      type
      company {
        id
        name
      }
    }
  }
`;

export const COMPANY_MAKE_PAYMENT = gql`
  mutation makeCompanyPayment(
    $transactionId: String!
    $paymentStatus: String!
    $paymentType: String!
    $numMonths: Int!
    $plan: String
  ) {
    companyPayment(
      billingArgs: {
        transactionId: $transactionId
        paymentStatus: $paymentStatus
        paymentType: $paymentType
        numMonths: $numMonths
        plan: $plan
      }
    ) {
      plan {
        name
      }
      billingHistory {
        transactionId
        paymentType
        transactionStatus
        numMonths
      }
    }
  }
`;
