<template>
  <div>
    <div v-if="changePass">
      <v-alert border="right" colored-border type="error" elevation="2">
        Please
        <v-btn class="" color="danger" plain @click="dialog = true">
          Click here
        </v-btn>
        to change your password to carry out all operations.
      </v-alert>
    </div>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5"> Change Your Password </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Old Password"
                  filled
                  v-model="old_password"
                  type="password"
                  :rules="emptyRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="New Password"
                  v-model="new_password"
                  filled
                  type="password"
                  :rules="emptyRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="changePassword"> Change </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { CHANGE_PASSWORD } from "../../constants/graphql";
import { handleError } from "../../constants/error";
import store from "@/store";
export default {
  data() {
    return {
      changePass: this.$store.state.authData.changePass,
      old_password: null,
      new_password: null,
      emptyRules: [(v) => !!v || "Field is required"],
      dialog: false,
      alertz: false,
    };
  },
  computed: {},
  methods: {
    changePassword() {
      this.$apollo
        .mutate({
          mutation: CHANGE_PASSWORD,
          variables: {
            oldPassword: this.old_password,
            newPassword: this.new_password,
          },
        })
        .then(() => {
          store.dispatch("setChangePasswordFalse");
          this.dialog = false;
          this.changePass = false;
          Swal.fire({
            html: `<div>Password changed successfully</div>`,
            icon: "success",
            customClass: {
              popup: "format-pre",
            },
          });
          //console.log(this.changePass);
        });
    },
    fillPassword() {
      //console.log(this.changePass);
    },
  },
  mounted() {
    this.fillPassword();
  },
};
</script>
