/*
 * ============================
 * File: main.js
 * Project: egret-vue
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://themeforest.net/user/ui-lib
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2020 UILIB, UILIB
 * ============================
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Flutterwave from "flutterwave-vue-v3";

import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";

import VueApollo from "vue-apollo";
import VueSweetalert2 from "vue-sweetalert2";
import Swal from "sweetalert2";

import vuetify from "./plugins/vuetify";
import "./plugins";

import egretKit from "@/plugins/egret.kit";
import MayventoryTable from "@/components/tables/MayventoryTable.vue";
import FirstLogin from "@/layouts/base/FirstLogin.vue";
import VueHtmlToPaper from "vue-html-to-paper";
import PaginatedDataTable from "@/components/tables/PaginatedDataTable.vue";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.use(Flutterwave, {
  publicKey:
    process.env.VUE_APP_FLUTTERWAVE_KEY ||
    "FLWPUBK_TEST-095760dd22bc5c0f3616f71c1d4088e9-X",
});

Vue.use(VueHtmlToPaper, {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
    "https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
});

import "animate.css";

const middlewareLink = setContext(() => ({
  headers: {
    Authorization: `Bearer ${store.getters.loggedInUser}`,
    "Access-Control-Allow-Origin": "*",
  },
}));

const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_URL || "http://174.138.52.88:8004/",
});

const link = middlewareLink.concat(httpLink);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    let errorMessage = "";
    graphQLErrors.forEach(
      ({ message, locations, path }) => (errorMessage += `${message}\n`)
    );
    Swal.fire({
      text: `Error Message: ${errorMessage}`,
      icon: "error",
    });
  }
  if (networkError) {
    Swal.fire({
      text: `[Network error]: ${networkError}`,
      icon: "error",
    });
  }
});

const apolloClient = new ApolloClient({
  link: errorLink.concat(link),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

Vue.component("first-login", FirstLogin);

Vue.component("mayventory-table", MayventoryTable);
Vue.component("paginated-data-table", PaginatedDataTable);
Vue.component("apexchart", VueApexCharts);

Vue.use(egretKit);
Vue.use(VueApollo);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: "loading",
  },
});

new Vue({
  store,
  router,
  vuetify,
  provide: apolloProvider.provide(),
  render: (h) => h(App),
}).$mount("#app");
