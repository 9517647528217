import { PERMISSION, GET_ROLES } from "../../../constants/graphql";

export default {
  state: {
    permissions: [],
    roles: [],
  },
  getters: {
    PERMISSION: (state) => {
      return state.permissions;
    },
    ROLES: (state) => {
      return state.roles;
    },
  },
  mutations: {
    SET_PERMISSION: (state, payload) => {
      state.permissions = payload;
    },
    SET_ROLES: (state, data) => {
      state.roles = data;
    },
  },
  actions: {
    GET_PERMISSION: async ({ commit }, payload) => {
      const data = await payload.apolloClient.query({
        query: PERMISSION,
        variables: {
          name,
        },
      });
      commit("SET_PERMISSION", data.data.getPermissions);
    },
    GET_ROLES: async ({ commit }, payload) => {
      const data = await payload.apolloClient.query({
        query: GET_ROLES,
        variables: {
          name,
        },
      });
      commit("SET_ROLES", data.data.getRoles);
    },
  },
  actions: {
    GET_PERMISSION: async ({ commit }, payload) => {
      const data = await payload.apolloClient.query({
        query: PERMISSION,
        variables: {
          name,
        },
      });
      commit("SET_PERMISSION", data.data.getPermissions);
    },
  },
};
