// Locale
// import { en } from '../../locale'

export const themeConfig = {
  disableCustomizer: false, // options[Boolean] : true, false(default)

  rtl: false, // options[Boolean] : true, false(default)
  verticalSidebarMini: false, // options[Boolean] : true, false(default)
  verticalSidebarDrawer: true, // options[Boolean] : true, false(default)
  verticalCompactSidebarDrawer: true, // options[Boolean] : true, false(default)
  verticalSaasSidebarDrawer: true, // options[Boolean] : true, false(default)
  showBreadcrumb: true, // options[Boolean] : true, false(default)

  layout: "VerticalSidebar", //VerticalCompactSidebar,VerticalSaasLayout,VerticalSidebar,HorizontalBar
  isLoading: false,

  isDark: false,
  verticalSidebarDrawerColor: "dark", // primary,white,sucsess,warning,etc
  appBarColor: "white", // primary,white,sucsess,warning,etc
};

export const themePreset = {
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
  //   icons: {
  //     iconfont: 'mdi',
  //     values: {},
  //   },
  //   lang: {
  //     current: 'en',
  //     locales: { en },nav
  //     t: undefined as any,
  //   },
  rtl: false,

  theme: {
    dark: themeConfig.isDark,

    default: "light",
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: "#0081FF",
        secondary: "#304156",
        success: "#09B66D",
        danger: "#FF3D57",
        warning: "#ff9f0a",
        info: "#5e5ce6",
        warning: "#FF8A48",
        dark: "#242939",
        black: "#242939",
        background: "#f2f3f8",
        color: "#304156",
      },
      dark: {
        primary: "#0081FF ",
        secondary: "#304156",
        success: "#09B66D",
        danger: "#FF3D57",
        warning: "#ff9f0a",
        info: "#5e5ce6",
        warning: "#FF8A48",
        color: "#fff",
      },
    },
  },
};
