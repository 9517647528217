import router from "../../router";
import { GET_COMPANY_SETTINGS } from "../../constants/graphql";

export default {
  state: {
    loggedInUser: localStorage.getItem("accessKey") || null,
    loading: false,
    error: null,
    userPermissions: [],
    changePass: false,
    userStore: [],
    userName: null,
    domain: null,
    company: [],
    currency: null,
    role: null,
  },
  getters: {
    loggedInUser: (state) => state.loggedInUser,
    loading: (state) => state.loading,
    error: (state) => state.error,
    userPermissions: (state) => state.userPermissions,
    changePass: (state) => state.changePass,
    userStore: (state) => state.userStore,
    userName: (state) => state.userName,
    domain: (state) => state.domain,
    company: (state) => state.company,
    currency: (state) => state.currency,
    role: (state) => state.role,
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data;
      state.loading = false;
      state.error = null;
      localStorage.setItem("accessKey", data);
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.loading = false;
      state.error = null;
      localStorage.removeItem("accessKey");
      router.go("/auth");
    },
    setUserPermissions(state, data) {
      state.userPermissions = data;
    },
    setChangePass(state, data) {
      state.changePass = data;
    },
    setUserName(state, payload) {
      state.userName = payload;
    },
    setUserStore(state, data) {
      state.userStore = data;
    },
    setLoading(state, data) {
      state.loading = data;
      state.error = null;
    },
    setDomain(state, data) {
      state.domain = data;
    },
    setCurrencyValue(state, data) {
      state.currency = data;
    },
    setCompany(state, data) {
      state.company = data;
    },
    setRole(state, payload) {
      state.role = payload;
    },
    setError(state, data) {
      state.error = data;
      state.loggedInUser = null;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    },
  },
  actions: {
    login({ commit }, payload) {
      commit("clearError");
      commit("setLoading", true);
      commit("setUser", payload.token);
      commit("setRole", payload.user.role.name);
      commit("setUserPermissions", payload.permissions);
      commit("setChangePass", payload.firstLogin);
      commit("setUserStore", payload.store);
      commit("setUserName", payload.user.name);
      commit("setDomain", payload.user.company.domain);
      commit("setCompany", payload.user.company);
    },

    setCurrency({ commit }, payload) {
      commit("setCurrencyValue", payload);
    },

    signOut({ commit }) {
      commit("setLogout");
    },

    setChangePasswordFalse({ commit }) {
      commit("setChangePass", false);
    },
  },
};
