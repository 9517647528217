export default (to, from, next) => {
  if (
    localStorage.getItem("accessKey") != null &&
    localStorage.getItem("accessKey").length > 0
  ) {
    next();
  } else {
    localStorage.removeItem("accessKey");
    next("/auth");
  }
};
